import { put } from 'redux-saga/effects';
import { Storage } from 'aws-amplify';
import { initScore, initScoreComplete } from '../../reducers/scores';

function* initScores() {
  try {
    const elements = yield Storage.list('scores/');
    for (const element of elements) {
      if (element.size > 0) {
        const url = yield Storage.get(element.key);
        const response = yield fetch(url);
        const content = yield response.json();
        yield put(initScore(content));
      }
    }
    yield put(initScoreComplete());
  } catch (exc) {
    console.error(exc);
  }
}

export default initScores;
