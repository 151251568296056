import React from 'react';
import { useTranslation } from 'react-i18next';

function Introduction(props) {
  const { t } = useTranslation();
  const { text } = props;
  return (
    <h2 className="mt-4 mx-4 md:mx-6">{t(text)}</h2>
  );
};

export default Introduction;
