import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';

export default function Question(props) {
  const { t } = useTranslation();
  const { name, answers } = props;
  return (
    <div id={name} className="bg-white shadow md:rounded-md p-4 md:p-6">
      <Field>
        {({ field, form }) => (
          <div className="flex items-center">
            <div className="flex-1 flex-col">
              <div className="flex-1 space-y-2">
                <div className="flex-1 items-center flex">
                  <p className={`flex-1 text-lg font-extrabold ${form.errors[name] ? 'text-red-700' : 'text-gray-700'}`}>{t(name)}</p>
                  {form.errors[name] && (
                    <span className="inline-flex items-center ml-2 px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                      Required
                    </span>
                  )}
                </div>
                <div className="mt-4 md:mt-6 space-y-2">
                  {answers.map((answer, index) => {
                    const handler = (event => {
                      const { setFieldValue, setFieldTouched } = form;
                      setFieldValue(name, {
                        text: t(answer),
                        value: index,
                      });
                      setFieldTouched(name);
                    });
                    return (
                      <div key={answer} className="cursor-pointer flex-1 flex-grow flex items-center">
                        <input onChange={handler} onBlur={field.onBlur} name={name} checked={form.values[name] && (form.values[name].value === answers.indexOf(answer))} type="radio" className="mr-4 focus:ring-playphysio h-4 w-4 text-playphysio border-gray-300" />
                        <label onClick={() => handler(name, answer)} className="cursor-pointer flex-1" htmlFor={`${name}-${answer}`}>{t(answer)}</label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
      </Field>
    </div>
  )
};
