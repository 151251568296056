import React from 'react';

import Header from '../../Header';

export default function Page(props) {
  const { children } = props;
  return (
    <div className="h-screen w-screen flex flex-col bg-white">
      <Header />
      <div className="flex-1 flex flex-col items-center">
        <div className="max-w-7xl">
          {children}
        </div>
      </div>
    </div>
  );
}
