import { put } from 'redux-saga/effects';
import { Storage } from 'aws-amplify';
import i18n from 'i18next';

import { initTranslation, initTranslationsComplete } from '../../reducers/translations';

function* initTranslationSaga() {
  try {
    const elements = yield Storage.list('translations/');
    for (const element of elements) {
      if (element.size > 0) {
        const url = yield Storage.get(element.key);
        const response = yield fetch(url);
        const content = yield response.json();
        i18n.addResources(content.lang, 'translation', content.items);
        yield put(initTranslation(content));
      }
    }
    yield put(initTranslationsComplete());
  } catch (exc) {
    console.error(exc);
  }
}

export default initTranslationSaga;
