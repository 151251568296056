import { createAction } from '@reduxjs/toolkit';

export const initScore = createAction('INIT_SCORE');
export const initScoreComplete = createAction('INIT_SCORE_COMPLETE');

const initialState = {
  items: {},
  loaded: false,
};

export default function Scores(state = initialState, action) {
  switch (action.type) {
    case initScore.type:
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.name]: action.payload.scores,
        },
      };
    case initScoreComplete.type:
      return {
        ...state,
        loaded: true,
      };
    default:
      return state;
  }
};
