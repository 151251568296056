import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../assets/playphysio-logo.png';

export default function Header() {
  return (
    <div className="flex-shrink min-w-0 shadow p-4 md:p-6">
      <Link to="/">
        <img src={Logo} alt="Playphysio logo" />
      </Link>
    </div>
  );
}