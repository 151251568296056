import { createAction } from '@reduxjs/toolkit';

export const initSurveys = createAction('INIT_SURVEY');
export const addSurvey = createAction('ADD_SURVEY');
export const initSurveysComplete = createAction('INIT_SURVEY_COMPLETE');

export const saveSurveyRequest = createAction('SAVE_SURVEY_REQUEST');
export const saveSurveyFailure = createAction('SAVE_SURVEY_FAILURE');
export const saveSurveySuccess = createAction('SAVE_SURVEY_SUCCESS');

const initialState = {
  items: [],
  loaded: false,
};

export default function Surveys(state = initialState, action) {
  switch (action.type) {
    case addSurvey.type:
      return {
        items: [
          ...state.items,
          action.payload
        ]
      };
    case initSurveysComplete.type:
      return {
        ...state,
        loaded: true,
      };
    default:
      return state;
  }
};
