import { createAction } from '@reduxjs/toolkit';

export const initTranslation = createAction('INIT_TRANSLATION');
export const initTranslationsComplete = createAction('INIT_TRANSLATIONS_COMPLETE');

const initialState = {
  loaded: false,
};

export default function Surveys(state = initialState, action) {
  switch (action.type) {
    case initTranslation.type:
      return {
        languages: {
          [action.payload.lang]: action.payload.items,
        },
      };
    case initTranslationsComplete.type:
      return {
        ...state,
        loaded: true,
      }
    default:
      return state;
  }
};
