import React from 'react';
import { connect } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';

import Page from '../../../components/layout/Page';
import SurveyView from '../../../components/Surveys/View';

const mapStateToProps = state => ({
  surveys: state.surveys.items,
  scores: state.scores.items,
});

function View(props) {
  const { survey_id } = useParams();
  const { surveys, scores } = props;
  const survey = surveys.find(({ id }) => id === survey_id);
  if (!survey) {
    return <Redirect to="/error"/>;
  }
  const survey_scores = scores[survey.name];
  return (
    <Page>
      <SurveyView survey={survey} scores={survey_scores} />
    </Page>
  );
}

export default connect(mapStateToProps)(View);
