import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const mapStateToProps = state => ({
  surveys: state.surveys.items,
});

function List(props) {
  const { t } = useTranslation();
  const { surveys } = props;
  return (
    <div className="bg-white shadow md:rounded-md">
      <ul className="divide-y divide-gray-200">
        {surveys.map(survey => {
          return (
            <li key={survey.id}>
              <Link to={`/surveys/${survey.id}`} className="block hover:bg-gray-50">
                <div className="px-4 py-4 flex items-center sm:px-6">
                  <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                    <div className="truncate">
                      <div className="flex text-sm">
                        {t(survey.name)}
                      </div>
                    </div>
                  </div>
                  <div className="flex-shrink-0">
                    <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                    </svg>
                  </div>
                </div>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default connect(mapStateToProps)(List);
