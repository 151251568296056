import React from 'react';
import { useTranslation } from 'react-i18next';

export default function SectionTitle(props) {
  const { t } = useTranslation();
  const { name } = props;
  return (
    <div className="my-4 flex justify-start">
      <span className="px-4 bg-white text-lg font-bold text-black-700">
        {t(name)}
      </span>
    </div>
  );
}
