import React from 'react';

import Page from '../../components/layout/Page';

export default function Error(props) {
  const { error } = props;
  return (
    <Page>
      <div className="flex-1 items-center">
        <h1 className="mt-8 text-4xl font-medium text-center">{error ?? "Something has gone wrong!"}</h1>
        <h3 className="mt-4 text-center">Contact Playphysio for assistance.</h3>
      </div>
    </Page>
  );
};
