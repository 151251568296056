export async function submitSurvey(data = {}) {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/surveys/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
    return response;
};
