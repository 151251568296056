import { put, takeEvery, call } from 'redux-saga/effects';
import { Storage } from 'aws-amplify';
import {
  initSurveys,
  addSurvey,
  initSurveysComplete,
  saveSurveyRequest,
  saveSurveyFailure,
  saveSurveySuccess
} from '../../reducers/surveys';

import i18n from 'i18next';

import { submitSurvey } from '../../../api/survey';

import { history } from '../../../App';

function* initSurveySaga() {
  try {
    const elements = yield Storage.list('elements/');
    for (const element of elements) {
      if (element.size > 0) {
        const url = yield Storage.get(element.key);
        const response = yield fetch(url);
        const content = yield response.json();
        yield put(addSurvey(content));
      }
    }
    yield put(initSurveysComplete());
  } catch (exc) {
    console.error(exc);
  }
}

function* submitSurveySaga(action) {
  const { scores, values } = action.payload;
  console.log(values);
  const scored_answers = [];
  let question_number = 1;
  for (const [key, value] of Object.entries(scores)) {
    const question = i18n.t(key);
    const index = values[key].value;
    const val = value.answers[index];
    scored_answers.push({
      name: key,
      type: value.type,
      question,
      question_number,
      value: val,
      text: values[key].text,
    });
    question_number++;
  }
  const graded = {};
  for (const obj of scored_answers) {
    graded[obj.type] = (graded[obj.type] ?? 0) + obj.value; 
  }
  for (const [key, val] of Object.entries(graded)) {
    const count = scored_answers.filter(obj => obj.type === key).length;
    graded[key] = ((val / count) - 1) / 3 * 100;
  };
  const toSubmit = {
    "patient_survey_secret": values.patient_survey_secret,
    "scored": graded,
    "answers": scored_answers,
    "raw_values": values,
  };
  try {
    const response = yield call(submitSurvey, toSubmit);
    if (response.status == 201) {
      yield history.push('/success');
    } else {
      yield history.push('/error');
    }
  } catch (exc) {
    yield history.push('/error');
  }
}

export default function* surveySaga() {
  yield takeEvery(initSurveys.type, initSurveySaga);
  yield takeEvery(saveSurveyRequest.type, submitSurveySaga);
}
