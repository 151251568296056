import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { Redirect, useParams } from 'react-router-dom';

import { saveSurveyRequest } from '../../../store/reducers/surveys';

import { renderSurveyItem, getRequiredFields } from '../../../utils/forms';

const mapStateToProps = state => ({
  scores: state.scores.items,
});

const mapDispatchToProps = dispatch => ({
  saveSurvey: (scores, values) => dispatch(saveSurveyRequest({
    scores: scores,
    values
  })),
})

function View(props) {
  const { t } = useTranslation();
  const { patient_survey_secret } = useParams();
  if (!patient_survey_secret) {
    return <Redirect to="/error" />;
  }
  const { survey, scores, saveSurvey } = props;
  return (
    <div className="min-w-0 mt-8 flex-1">
      <h1 className="mx-4 md:mx-6 flex-1 text-2xl font-bold text-gray-700">{t(`qst-${survey.name}`)}</h1>
      <Formik
        initialValues={{
          patient_survey_secret,
        }}
        validateOnChange={true}
        validateOnBlur={false}
        validate={values => {
          const required_fields = getRequiredFields(survey);
          const errors_arr = [];
          const errors = {};
          for (const field of required_fields) {
            if (values[field] === undefined) {
              errors_arr.push(field);
              errors[field] = "Required";
            }
          }
          if (errors_arr.length > 0) {
            const element =  document.getElementById(errors_arr[0]);
            if (element) {
              element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          }
          console.log('Errors');
          console.log(errors);
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting();
          for (const [key, value] of Object.entries(values)) {
             const question_answers = scores[key];
             if (question_answers) {
              const answers = question_answers.answers;
              if (answers) {
                values[key].value = answers[value.value];
              }
            }
          }
          values['patient_survey_secret'] = patient_survey_secret;
          saveSurvey(scores[survey.name], values);
        }}
      >
        {({ isSubmitting, setFieldValue, setFieldTouched }) => (
          <Form className="flex-1 flex-col">
              {survey.items.map(item => renderSurveyItem(item, t, setFieldValue, setFieldTouched))}
            <div className="flex-1 flex justify-end mr-8 mt-8 mb-8">
              <button type="submit" disabled={isSubmitting} className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Submit
            </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(View);
