import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';

function DateInput(props) {
  const { t } = useTranslation();
  const { name, setFieldValue } = props;
  return (
    <Field name={name}>
      {({ form }) => (
        <div id={name} className="bg-white shadow md:rounded-md p-4 md:p-6">
          <div className="flex-1 items-center flex">
            <p className={`flex-1 text-lg font-extrabold ${form.errors[name] ? 'text-red-700' : 'text-gray-700'}`}>{t(name)}</p>
            {form.errors[name] && (
              <span className="inline-flex items-center ml-2 px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                Required
              </span>
            )}
          </div>
          <input type="date" onChange={event => setFieldValue(name, event.target.value)} className="mt-4 shadow-sm focus:ring-playphysio focus:border-playphysio block w-full sm:text-sm border-gray-300 rounded-md" />
        </div>
      )}
    </Field>
  );
};

export default DateInput;
