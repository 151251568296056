import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { initSurveys } from '../store/reducers/surveys';

import Loading from '../pages/Loading';

const mapStateToProps = state => ({
  surveys: state.surveys.loaded,
  scores: state.scores.loaded,
  translations: state.translations.loaded,
});
const mapDispatchToProps = dispatch => ({
  initSurveys: () => dispatch(initSurveys()),
});

function DataGuard(props) {
  const { initSurveys, children, surveys, scores, translations } = props;
  useEffect(() => {
    initSurveys();
  }, [initSurveys]);
  if (!surveys || !scores || !translations) {
    return (
      <Loading />
    );
  }
  return (
    <div>
      {children}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DataGuard);
