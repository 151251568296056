import React from 'react';
import { Provider } from 'react-redux'
import {
  Router,
  Route,
  Switch
} from 'react-router-dom';
import { createBrowserHistory } from "history";

import store from './store';
import './main.css';

import DataGuard from './guards/data';

import Home from './pages/Home';
import ViewSurvey from './pages/Surveys/View';
import Error from './pages/Error';
import Success from './pages/Success';

export const history = createBrowserHistory();

function App() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <DataGuard>
          <Switch>
            <Route exact path="/surveys/:survey_id/:patient_survey_secret">
              <ViewSurvey />
            </Route>
            <Route exact path="/success">
              <Success />
            </Route>
            <Route exact path="/surveys">
              <Home />
            </Route>
            <Route component={Error} />
          </Switch>
        </DataGuard>
      </Router>
    </Provider>
  );
}

export default App;
