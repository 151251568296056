import SectionTitle from '../components/Surveys/SectionTitle';
import Question from '../components/Surveys/Question';
import QuestionGroup from '../components/Surveys/QuestionGroup';
import Text from '../components/Surveys/Text';
import Introduction from '../components/Surveys/Introduction';
import DateInput from '../components/Surveys/DateInput';
import NumberInput from '../components/Surveys/NumberInput';

export const renderSurveyItem = (item, t, setFieldValue, setFieldTouched) => {
  switch (item.type) {
    case 'section':
      return (
        <div name={item.key} className="space-y-6">
          <h2 className="mx-4 md:mx-6 mt-4 md:mt-8 text-playphysio text-2xl font-extrabold">{t(item.key)}</h2>
          {item.content.map(it => renderSurveyItem(it, t, setFieldValue, setFieldTouched))}
        </div>
      );
    case 'section-title':
      return <SectionTitle name={item.key} />;
    case 'group':
      return <QuestionGroup name={item.key} answers={item.answers} questions={item.questions} />;
    case 'question':
      return <Question name={item.key} answers={item.answers} />;
    case 'text':
      return <Text name={item.key} text={item.key} />
    case 'introduction':
      return <Introduction name={item.key} text={item.key} />
    case 'date-input':
      return <DateInput name={item.key} setFieldValue={setFieldValue} />
    case 'number-input':
      return <NumberInput name={item.key} setFieldValue={setFieldValue} />
    default:
      return <></>;
  }
};

const flattenQuestions = (items) => {
  let flattened = [];
  for (const item of items) {
    switch (item.type) {
      case 'section':
        flattened = [...flattened, ...flattenQuestions(item.content)];
        break;
      case 'group':
        flattened = [...flattened, ...item.questions];
        break;
      case 'question':
        flattened.push(item.key);
        break;
      case 'date-input':
        flattened.push(item.key);
        break;
      case 'number-input':
        flattened.push(item.key);
        break;
      default:
    }
  }
  return flattened;
}

export const getRequiredFields = (survey) => {
  return flattenQuestions(survey.items);
};
