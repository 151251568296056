import React from 'react';

import Page from '../../components/layout/Page';
import SurveyList from '../../components/Surveys/List';

export default function Home() {
  return (
    <Page>
      <SurveyList />
    </Page>
  );
}
