import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';

export default function QuestionGroup(props) {
  const { t } = useTranslation();
  const { name, answers, questions } = props;
  return (
    <div className="bg-white shadow md:rounded-md p-4 md:p-6">
      <h2 className="text-playphysio text-lg font-bold mb-4">{t(name)}</h2>
      <ul className="space-y-6">
        {questions.map(question => {
          return (
            <li id={question} key={question}>
              <Field>
                {({ field, form, meta }) => (
                  <div className="flex flex-col">
                    <div className="flex-1 flex flex-col">
                      <div className="flex flex-row items-center">
                        <p className={`flex-1 text-lg font-extrabold ${form.errors[question] ? 'text-red-700' : 'text-gray-700'}`}>{t(question)}</p>
                        {form.errors[question] && (
                          <span className="inline-flex items-center ml-2 px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                            Required
                          </span>
                        )}
                      </div>
                      <div className="mt-4 md:mt-6 flex flex-col md:flex-row">
                        {answers.map((answer, index) => {
                          const handler = (event => {
                            const { setFieldValue, setFieldTouched } = form;
                            setFieldValue(question, {
                              text: t(answer),
                              value: index,
                            });
                            setFieldTouched(question);
                          });
                          return (
                            <div key={answer} onClick={handler} className="hover:bg-green-200 flex-grow flex items-center justify-start cursor-pointer rounded-md">
                              <div className="p-2 md:p-4 flex-1 flex flex-row items-center">
                                <input onBlur={field.onBlur} onChange={handler} id={`${question}-${answer}`} checked={form.values[question] && (form.values[question].value === answers.indexOf(answer))} type="radio" className="cursor-pointer wh-6 focus:ring-playphysio text-playphysio border-gray-300" />
                                <p className="ml-4">{t(answer)}</p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
              </Field>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
