import React from 'react';
import { useTranslation } from 'react-i18next';

function Text(props) {
  const { t } = useTranslation();
  const { text } = props;
  return (
    <p className="flex-1 mt-4 mx-4 md:mx-6">{t(text)}</p>
  );
};

export default Text;
