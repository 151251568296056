import React from 'react';

import Page from '../../components/layout/Page';

export default function Success(props) {
  return (
    <Page>
      <div className="flex-1 items-center visible md:invisible mx-4">
        <h1 className="mt-8 text-4xl font-medium text-center">Thank you very much for completing our questionnaire!</h1>
        <p className="mt-4 text-center">Your feedback is essential in helping us to develop Playphysio, your answers have been received by the team.</p>
        <p className="mt-8 text-blue-400 text-center">Please tap 'Done' above to continue.</p>
      </div>
        <div className="flex-1 items-center invisible md:visible">
          <h1 className="mt-8 text-4xl font-medium text-center">Thank you very much for completing our questionnaire!</h1>
          <p className="mt-4 text-center">Your feedback is essential in helping us to develop Playphysio, your answers have been received by the team.</p>
          <p className="mt-8 text-blue-400 text-center">You may now close this browser window.</p>
        </div>
    </Page>
  );
};
