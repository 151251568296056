import { configureStore, combineReducers } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import scores from './reducers/scores';
import surveys from './reducers/surveys';
import translations from './reducers/translations';

import surveySaga from './sagas/surveys';
import scoresSaga from './sagas/scores';
import translationsSaga from './sagas/translations';

const sagaMiddleware = createSagaMiddleware()

export default configureStore({
  reducer: combineReducers({
    scores,
    surveys,
    translations,
  }),
  middleware: [sagaMiddleware],
});

sagaMiddleware.run(surveySaga);
sagaMiddleware.run(scoresSaga);
sagaMiddleware.run(translationsSaga);
